import Vue from 'vue';
import Vue2Filters from 'vue2-filters'
import {Vue2FiltersConfig} from './filters'

window.Vue = Vue;

Vue.use(Vue2Filters, Vue2FiltersConfig);

export default  {}



