<script>
    import moment from 'moment'

    export default {
        props: ['errors', 'url', 'investment'],
        data() {
            return {
                dialogVisible: false,
                form: {
                    'maturity_date': null,
                    'rate': null,
                    'interest_payment_duration': null,
                    'on_call': false
                },
                commissions: [
                    {
                      "name": "Full",
                      "value": "1"
                    },
                    {
                      "name": "Half",
                      "value": "0.5"
                    },
                    {
                      "name": "None",
                      "value": "0"
                    }
                ]
            }
        },
        created: function () {
            this.form.maturity_date = moment(this.investment.maturity_date).format('YYYY-MM-DD');
            this.form.interest_payment_duration = this.investment.interest_payment_duration;
            this.form.rate = this.investment.rate;
            this.form.on_call = this.investment.on_call;
            this.form.awarded_commission = this.investment.awarded_commission;
        }
    }
</script>
<template>
    <div>

        <button @click="dialogVisible = true" class="button"><i class="el-icon-edit"></i> Edit</button>

        <el-dialog
                title="Edit Investment"
                :visible.sync="dialogVisible"
        >
            <span>
                <div class="holder pl-4 pb-4">
                    <span>Amount Available: {{ investment.total_maturity | currency('') }}</span><br>
                    <span>Value Date: {{ investment.date | date }}</span> <br>
                    <span>Maturity Date: {{ investment.maturity_date | date }}</span>
                </div>
                <form method="POST" class="form wide cols2" :action="url">
                    <slot name="csrf"></slot>
                    <input type="hidden" name="investment_id" v-model="investment.id"/>

                    <div class="holder">
                          <label for="maturity_date">Maturity Date | Tenor: {{ tenor_days }} days </label>
                          <el-date-picker
                                  v-model="form.maturity_date"
                                  type="date"
                                  placeholder="Pick a day"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  required="true"
                                  id="maturity_date"
                          >
                            </el-date-picker>
                            <input name="maturity_date" type="hidden" v-model="form.maturity_date"/>
                            <form-errors name="maturity_date" :errors="errors"></form-errors>
                      </div>

                      <div class="holder">
                          <input type="checkbox" name="on_call" id="on_call" value="True" v-model="form.on_call">
                          <label for="on_call">On Call</label>
                      </div>

                      <div class="holder">
                          <label for="rate">Interest Rate (%)</label>
                          <input name="rate" type="number" id="rate" v-model="form.rate" step="0.001" required="true"/>
                          <form-errors name="rate" :errors="errors"></form-errors>
                      </div>

                      <div class="holder">
                          <label for="interest_payment_duration">Interest Payment</label>
                          <select name="interest_payment_duration" id="interest_payment_duration"
                                  v-model="form.interest_payment_duration">
                              <option selected value="0">On Maturity</option>
                              <option value="1">Monthly</option>
                              <option value="3">Quarterly</option>
                              <option value="6">Every 6 Months</option>
                              <option value="12">Annually</option>
                          </select>
                          <form-errors name="interest_payment_duration" :errors="errors"></form-errors>
                      </div>
                      <div class="holder">
                        <label for="awarded_commission">Awarded Commission</label>
                        <select name="awarded_commission" id="awarded_commission" v-model="form.awarded_commission">
                            <option
                                v-for="comm in commissions"
                                v-bind:value="comm.value"
                                :selected="comm.value === form.awarded_commission"
                            >{{ comm.name}}</option>
                        </select>
                        <form-errors name="awarded_commission" :errors="errors"></form-errors>
                      </div>

                    <div class="w-full">
                        <div class="button-group holder">
                            <button type="submit" class="success button">Save</button>
                            <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                        </div>
                    </div>
                </form>
          </span>
        </el-dialog>
    </div>
</template>