require('./bootstrap');
require('./components');
require('./elements/elementui');
require('./filters');

import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const app = new Vue({
    created() {
        this.user = window.user;
    }
}).$mount('#app');

const flash_messages = function (errors) {
    errors = JSON.parse(errors)

    for(let i=0; i < errors.length; i++) {
        setTimeout(function () {
            app.$notify({
                message: errors[i].message,
                type: errors[i].level,
                duration: 20000 + (i * 200),
                dangerouslyUseHTMLString: true,
            });
        }, i * 300);
    }
}

axios.interceptors.response.use(
    function (res) {
        if(res.headers['x-messages']){
            flash_messages(res.headers['x-messages'])
        }

        return res
    },
    function (err) {
        if(err.response.headers['x-messages']){
            flash_messages(err.response.headers['x-messages'])
        }
        return Promise.reject(err);
    }
)


