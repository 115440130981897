<script>
    import axios from 'axios'
    import debounce from '../../helpers'

    export default {
        props: ['url', 'columns','search', 'actions', 'filters', 'formats'],
        data(){
            return {
                data: {},
                pagination: {},
                search_text: '',
                order_by: null,
                filter_text: {},
                somefilter: 'currency',
                type_choices:[]
            }
        },
        computed: {
            provided_columns: function () {
                return this.columns ? this.columns : {}
            },
            labels: function () {
                return Object.values(this.provided_columns)
            },
            cols: function () {
                return Object.keys(this.provided_columns)
            },
            params: function () {
                return Object.assign({search: this.search_text, ordering: this.order_by}, this.filter_text);
            },
            my_formats: function () {
                return this.formats ? this.formats : {}
            }
        },
        methods: {
            getColumn: function (row, column) {
                let reducers = column.trim().split('.');
                let val = row;

                for(let i=0; i < reducers.length ; i++) {
                    val = val[reducers[i]]
                }

                return this.format(column, val)
            },
            format: function(column, value){
                let format_keys = Object.keys(this.my_formats);

                if(!format_keys.includes(column)){
                    return value
                }

                return Vue.filter(this.my_formats[column])(value)
            },
            order: function(col){
                this.order_by = col;

                this.do_search();
            },
            formatLink: function(row, link){
                return link.replace('<id>', row.id)
            },
            fetch: function(url){
                const vm = this;

                url = url ? url : this.url;

                axios.get(url, {'params': this.params})
                    .then(function (response) {
                        vm.data = response.data.results;
                        vm.pagination = {
                            count: response.data.count,
                            next: response.data.next,
                            previous: response.data.previous
                        };
                    },
                    function () {
                        vm.$notify.error({
                          title: 'Error',
                          message: 'Failed while loading table data'
                        });
                    }) 
            },
            nextPage: function () {
                this.fetch(this.pagination.next)
            },
            prevPage: function () {
                this.fetch(this.pagination.previous)
            },
            getKeyByValue: function (object, value) {
              return Object.keys(object).find(key => object[key] === value);
            },
            do_search: debounce(function () {
                this.fetch()
            }, 500),
        },
        created: function () {
            this.fetch()
            
        },
        mounted(){
            let filters = {}
            if (this.filters) {
                this.filters.filter(item => item.type === 'select').forEach(item => filters[item.field] = '')
                this.filter_text = filters
            }
        }
    }
</script>
<template>
    <div class="w-full">
        <div class="w-full flex-wrap">
            <template v-for="filter in filters">
                <input v-on:keyup="do_search()" v-if="filter.type === 'text'" type="text" class="w-full md:w-1/6 ml-2" :placeholder="filter.label" v-model="filter_text[filter.field]">
                <select @change="do_search()" v-if="filter.type === 'select'" class="w-full md:w-1/6 ml-2 px-6" :placeholder="filter.label" v-model="filter_text[filter.field]">
                    <option selected value="">{{ filter.label ? filter.label : 'None' }}</option>
                    <option v-for="choice in filter.choices" :value="choice.id">{{ choice.name}}</option> 
                </select>
            </template>

            <input type="text" v-on:keyup="do_search()" v-model="search_text" v-if="search" class="w-full md:w-1/6  ml-2 float-right" placeholder="Search...">
        </div>
        <table>
            <thead>
                <tr>
                    <th v-for="label in labels" @click="order(getKeyByValue(columns, label))">
                        {{ label }}
                    </th>
                    <th v-if="actions">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in data">
                    <td v-for="col in cols">
                        {{ getColumn(row, col)  }}
                    </td>
                    <td v-if="actions">
                        <div class="small button-group mb-0">
                            <a :href="formatLink(row, link)" class="small button" v-for="(link, label) in actions">{{ label }}</a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div>
            <small>
                <a v-if="pagination.previous" @click="prevPage()"><i class="el-icon-arrow-left"></i> Previous</a>
                <span v-if="pagination.next && pagination.previous"> | </span>
                <a v-if="pagination.next" @click="nextPage()">Next <i class="el-icon-arrow-right" ></i></a>
                <span v-if="pagination.count > 0" class="float-right">
                    <i class="fas fa-check-circle"></i> {{ pagination.count }} {{ pagination.count === 1 ? 'record' : 'records'}} found
                </span>
                <span v-if="pagination.count === 0" class="float-right">
                    <i class="far fa-times-circle"></i> No records found
                </span>
                <span class="clearfix"></span>
            </small>
        </div>
    </div>
</template>
<style scoped>
    .button {
        margin-bottom: 0;
    }
    input, select {
        display: inline;
        margin-bottom: 5px;
        margin-top: 0;
        align-items: center;
        padding-left: 10px;
    }
</style>