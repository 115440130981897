<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        props: ['approval_id'],
        data(){
            return {
                'dialogVisible': false,
                'comment': '',
                'comments': []
            }
        },
        computed: {
            'comments_count': function () {
                return this.comments.length
            }
        },
        filters: {
            'time_ago': function (now) {
                return moment(now).fromNow();
            }
        },
        methods: {
            'submit': function () {
                let vm = this;
                axios.post('approvals/'+this.approval_id+'/comment', {'comment': this.comment})
                .then(function () {
                    vm.get()
                    vm.comment = ''
                    vm.$message('Comment saved.');
                }, function () {

                })
            },
            'is_own': function (comment) {
                const user = window.user;

                return parseInt(user.id) === comment.user.id
            },
            'not_own': function (comment) {
                return !this.is_own(comment)
            },
            'get': function () {
                let vm = this

                axios.get('/api/approvals/'+this.approval_id+'/comments').then(function ({data}) {
                    vm.comments = data
                }, function () {
                    vm.$message('Could not fetch comments');
                })
            }
        },
        created(){
            this.get()
            let vm = this
            setInterval(function () {
                vm.get()
            }, 10000)
        }
    }
</script>
<template>
    <span>
        <a @click="dialogVisible = true" class="small success button float-right"><i class="fas fa-comment"></i> {{ comments_count }} Comment{{ comments_count !==1 ? 's' : '' }}</a>

        <el-dialog
          title="Comments"
          :visible.sync="dialogVisible">
          <div>
              <div class="w-full bg-gray-100 pt-5 pb-2 px-5">
                  <div class="w-full">
                      <template v-for="comment in comments">
                          <div class="w-3/4" :class="{'text-right float-right is-own ': not_own(comment), 'text-left align-left not-own': is_own(comment) }">
                              <div class="flex" :class="{'flex-row-reverse': not_own(comment)}">
                                  <div class="shrink">
                                    <img defer class="h-12 w-12 rounded-full inline mr-2" :src="'https://www.gravatar.com/avatar/'+comment.user.gravatar+'?s=200&d=identicon'"/>
                                  </div>
                                  <div class="mbox-shaded flex flex-wrap bg-gray-800 mb-2 px-4 py-2 rounded-lg text-white" :class="{'mr-2': not_own(comment) }">
                                      <div class="w-full text-gray-400">
                                          <span class="small"><i class="far fa-user"></i> {{ comment.user.name }}</span>
                                          <span class="small"><i class="far fa-clock"></i> {{ comment.created_at | time_ago }}</span>
                                          <span class="small"><i class="fas fa-tasks"></i> {{ comment.stage.name }}</span>
                                      </div>
                                      <div class="w-full">
                                          <span>{{ comment.comment }}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="clearfix"></div>
                      </template>

                  </div>

                  <form @submit.prevent="submit">
                      <div class="holder">
                          <input class="comment-box" type="text" placeholder="Type a comment then press enter" v-model="comment">
                      </div>
                  </form>
              </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="secondary button" @click.prevent="dialogVisible = false">Close</button>
          </span>
        </el-dialog>
    </span>
</template>
<style scoped>
    .comment-box{
        height: 4em;
        box-shadow: none;
        margin-top: 15px;
    }
    .mbox-shaded {
        width: 80%;
    }
    .is-own .mbox-shaded {
        border-bottom-right-radius: 0;
    }
    .not-own .mbox-shaded{
        border-bottom-left-radius: 0;
    }
    .mbox-shaded span.small{
        margin-right: 20px;
        font-size: 0.8em;
    }
</style>
