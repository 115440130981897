import Vue from 'vue'
import moment from 'moment'


export const Vue2FiltersConfig = {
  percent: {
    decimalDigits: 2
  },
  currency: {
    symbol: '',
    decimalDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false
  }
};

Vue.filter('date', function (value) {
    if (!value) return ''

    return moment(value).format('MMM. D, Y')
});

Vue.filter('timeago', function (value){
    if (!value) return ''

    return moment(value).fromNow();
})