<script>
    export default {
        props: ['url'],
        data() {
            return {
                dialogVisible: false,
                repeats: [1]
            }
        },
        methods: {
            add: function () {
                this.repeats.push(1)
            }
        },
        computed: {

        },
        created: function () {

        }
    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="success button"><i class="el-icon-plus"></i> Create Rate</button>

        <el-dialog
          title="Invest"
          :visible.sync="dialogVisible"
        >
          <span>
              <form method="POST" class="form wide cols3" :action="url">
                  <slot name="csrf"></slot>

                  <slot name="form"></slot>

                  <div class="clearfix"></div>


                  <div class="w-full">

                  </div>
                  <template v-for="r in repeats">
                      <div class="holder">
                          <label>Min. Amount</label>
                          <input type="number" name="min_amount[]" placeholder="Amount">
                      </div>
                      <div class="holder">
                          <label>Months</label>
                          <input type="number" name="min_months[]" placeholder="Months">
                      </div>
                      <div class="holder">
                          <label>Rate</label>
                          <input type="number" name="rate[]" placeholder="Rate %" step="0.01">
                      </div>
                  </template>
                  <div class="w-full pr-4">
                      <div class="button-group float-right">
                          <a class="small button" @click.prevent="add()"><i class="el-icon-plus"></i></a>
                      </div>
                  </div>

                  <div class="w-full">
                      <div class="button-group holder">
                          <button type="submit" class="success button">Save</button>
                          <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                      </div>
                  </div>
              </form>
          </span>
        </el-dialog>
    </div>
</template>