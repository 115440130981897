<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        props: ['client', 'approved'],
        data(){
            return {
                dialogVisible: false,
                loading: false,
                options: [],
                form: {
                    fa_id: null,
                    date: moment().format('YYYY-MM-DD')
                }
            }
        },
        methods: {
            open: function () {
                this.dialogVisible = true
            },
            fetch_records: function(query){
                let vm = this;

                axios.get('/api/financial_advisers', {'params': {'search': query}})
                .then(function ({data}) {
                    vm.options = data.results
                }, function () {

                }).finally(function () {
                    vm.loading = false
                });
                vm.loading = true
            },
            submit: function () {
                let vm = this;

                axios.post('/clients/financial_advisers/'+this.client+'/add_client', this.form)
                .then(function () {
                    vm.dialogVisible = false;
                    vm.$notify({ message: "Financial Advisor saved",  type: 'success' });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000)
                }, function () {
                    vm.$notify({ message: "Could not save FA",  type: 'error' });
                })
            }
        }
    }
</script>
<template>
    <span>
        <el-dialog
          title="Add Financial Adviser"
          :visible.sync="dialogVisible"
        >
          <div>
              <form class="wide">
                  <div class="holder">
                      <label>Select FA</label>
                      <el-select
                        v-model="form.fa_id"
                        filterable
                        remote
                        placeholder="Enter fa name/email to search"
                        :remote-method="fetch_records"
                        :loading="loading">
                        <el-option
                          v-for="item in options"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                  </div>
                  <div class="holder">
                    <label>Start Date</label>
                    <el-date-picker
                      v-model="form.date"
                      type="date"
                      placeholder="Pick a day"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
              </form>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="secondary button" @click="dialogVisible = false">Cancel</button>
            <button class="button" type="primary" @click="submit()">Save</button>
          </span>
        </el-dialog>

        <a @click="open()" class="button"><i class="el-icon-plus"></i> Add Financial Advisor</a>
    </span>
</template>
<style>
    el-select, .el-select {
        width: 100%;
    }
</style>