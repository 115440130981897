<script>
    export default {
        props: ['errors', 'url', 'investment'],
        data() {
            return {
                dialogVisible: false,
                form: {
                    'fa':null,
                    'comm_date': null
                },
            }
        },
        created: function () {
            this.form.fa = this.investment.fa;
            this.form.comm_date = this.investment.date
        }
    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="button"><i class="el-icon-edit"></i> Create Investment Commissions</button>
        <el-dialog
                title="Create Investment Commissions"
                :visible.sync="dialogVisible"
        >
            <span>
                <form method="POST" class="form wide cols2" :action="url">
                    <slot name="csrf"></slot>
                    <input type="hidden" name="investment_id" v-model="investment.id"/>
                    <input type="hidden" name="fa" id="fa" value="form.fa" v-model="form.fa">

                    <div class="holder">
                        <label for="comm_date">New Commission Cycle Start Date</label>
                        <input type="date" name="comm_date" v-model="form.comm_date"/>
                        <form-errors name="comm_date" :errors="errors"></form-errors>
                    </div>
                    <div class="w-full">
                        <div class="button-group holder">
                            <button type="submit" class="success button">Edit Commissions</button>
                            <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                        </div>
                    </div>
                </form>
          </span>
        </el-dialog>
    </div>
</template>