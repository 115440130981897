<template>
  <div class="shadow-xs flex flex-wrap">
    <div class="w-full md:w-1/2 bg-white">
      <span>
        <a href="/" class="logo">
          <img class="h-16"  src="/assets/admin_app/resources/genghis_logo.png">
        </a>
      </span>
    </div>

    <el-menu :default-active="activeIndex"
             class="el-menu-demo w-full md:w-1/2"
             mode="horizontal"
             @select="handleSelect"
    >
      <notification></notification>
      <el-submenu index="2" class="float-right" v-if="user.is_authenticated">
        <template slot="title">
          <img class="h-12 w-12 rounded-full inline mr-2"
               :src="'https://www.gravatar.com/avatar/'+user.gravatar+'?s=200&d=mystery-person'"
          />
          {{ user.name.trim() !== '' ? user.name : user.username }}
        </template>
        <el-menu-item index="2-1"><a href="/profile">Profile</a></el-menu-item>
        <el-menu-item index="2-2"><a href="/logout">Logout</a></el-menu-item>
      </el-submenu>
      <div class="float-right pt-4 pr-8" v-else>
          <a href="/login"><i class="fas fa-sign-in-alt"></i> Login</a>
      </div>
    </el-menu>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeIndex: '1',
        activeIndex2: '1',
        user: window.user
      };
    },
    methods: {
      handleSelect(key, keyPath) {

      }
    }
  }
</script>
<style scoped>
  .float-right {
    float: right !important;
  }
  .logo {
    width: auto;
    display: inline-block;
  }
</style>