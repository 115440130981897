<script>
    import axios from 'axios'
    export default {
        data(){
            return {
                loading: false,
                token_sent: false,
                form: {
                    'username': null,
                    'password': null,
                    'token': null
                }
            }
        },
        methods: {
            submit: function () {
                let vm = this;
                axios.post('login-2fa/', vm.form)
                .then(function ({data}) {
                    if(!data.success){
                        vm.$notify({
                            message: data.message,
                            type: 'error',
                            duration: 20000
                         });
                    }
                    else {
                        vm.token_sent = true;
                        vm.$notify({
                            message: 'Token has been sent to your email',
                            type: 'success',
                            duration: 20000
                        });
                    }

                }, function ({data}) {
                    vm.$notify({
                        message: 'Could not send token',
                        type: 'error',
                        duration: 20000
                    });
                }).finally(function () {
                    vm.loading = false
                });

                this.loading = true
            }
        }
    }
</script>
<template>
    <form method="POST" class="w-full sm:w-3/4 md:w-1/2 ml-auto mr-auto">
        <slot></slot>

        <div id="div_id_username" class="holder">
            <label for="id_username" class="required">Username<span class="asterisk">*</span></label>
            <input type="text" name="username" autofocus="autofocus" autocapitalize="none" autocomplete="off" maxlength="150" required="required" id="id_username" class="textinput textInput" v-model="form.username">
        </div>

        <div id="div_id_password" class="holder">
            <label for="id_password" class="required">Password<span class="asterisk">*</span></label>
            <input type="password" name="password" autocomplete="off" required="required" id="id_password" class="textinput textInput" v-model="form.password">
        </div>

        <div id="div_id_token" class="holder" v-if="token_sent">
            <label for="id_token" class="required">Token (from your email)<span class="asterisk">*</span></label>
            <input type="text" name="token" autocomplete="off" required="required" id="id_token" class="textinput textInput" v-model="form.token">
        </div>

        <div class="holder" v-if="!token_sent">
            <button @click.prevent="submit()" class="expanded rounded button"><i v-if="loading" class="el-icon-loading"></i> <i v-else class="el-icon-message"></i> Request Token</button>
        </div>

        <div class="holder" v-if="token_sent">
            <button class="expanded rounded button" type="submit"><i class="fas fa-sign-in-alt"></i> Login</button>
        </div>
    </form>
</template>