<script>
    import axios from 'axios'

    export default {
        props: ['url', 'amount'],
        data() {
            return {
                'percentage': NaN,
                'status': null
            }
        },
        created: function () {
            let vm = this;
            let amount = this.amount ? this.amount : 0

            axios.get(this.url, {'params': {'amount': amount}}).then(function ({data}) {
                vm.percentage = data.percentage;
                vm.status = data.status;
            }, function () {
                vm.$message('Could not fetch compliance status');
            });
        }
    }
</script>
<template>
    <div>
        <template v-if="!isNaN(percentage)">
            <span>{{ percentage }}%</span>
            <span>
                <i class="el-icon-check text-green-500 text-xl float-right" v-if="status"></i>
                <i class="el-icon-close text-red-500 text-xl float-right" v-else></i>
            </span>
        </template>
        <span v-else>Fetching...</span>
    </div>

</template>