<script>
    import moment from 'moment'

    export default {
        props:['name', 'value', 'attrs'],
        data(){
            return {
                date: null,
                attributes: {}
            }
        },
        created: function () {
            if(this.value) {
                this.date = moment(this.value).format('YYYY-MM-DD')
            }
            if(this.attrs) {
                this.attributes = JSON.parse(this.attrs)
            }
        }
    }

    //TODO apply attributes

</script>
<template>
    <el-date-picker
      type="date"
      :name="name"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      v-model="date"
      v-bind="attributes"
      placeholder="Pick a date">
    </el-date-picker>
</template>