<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        props: ['url'],
        data(){
            return {
                dialogVisible: false,
                loading: false,
                options: [],
                form: {
                    reason: '',
                }
            }
        },
        methods: {
            open: function () {
                this.dialogVisible = true
            },
            submit: function () {
                let vm = this;

                axios.post(this.url, this.form)
                .then(function () {
                    vm.dialogVisible = false;
                    vm.$notify({ message: "Saved for approval",  type: 'success' });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000)
                }, function () {
                    vm.$notify({ message: "Request failed",  type: 'error' });
                })
            }
        }
    }
</script>
<template>
    <span>
        <el-dialog
          title="Deactivate Client"
          :visible.sync="dialogVisible"
        >
          <div>
              <form class="wide">
                  <div class="holder">
                      <label>Enter reason</label>
                      <input type="text" v-model="form.reason" minlength="5" required>
                  </div>
              </form>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="secondary button" @click="dialogVisible = false">Cancel</button>
            <button class="button" type="primary" @click="submit()">Save</button>
          </span>
        </el-dialog>

        <a @click="open()" class="alert button"><i class="el-icon-close"></i> Deactivate Client</a>
    </span>
</template>
<style>
    el-select, .el-select {
        width: 100%;
    }
</style>