<script>
    import axios from 'axios'

    export default {
        props: ['account', 'client_id', 'approval_id'],
        data(){
            return {

            }
        },
        methods: {
            open: function() {
                let vm = this;

                this.$alert('Do you want to delete account \n Name: '+this.account.name+ ' Number: '+this.account.number, 'Delete', {
                  confirmButtonText: 'Delete',
                  callback: action => {
                    if(action === 'confirm') {
                        axios.get("/clients/bank/client/"+vm.client_id+"/"+vm.account.id+"/delete")
                          .then(function () {
                              let msg = vm.approval_id ? "Saved for approval" : "Bank account removed successfully";
                              vm.$notify({ message: msg,  type: 'success' });
                                setTimeout(function () {
                                    window.location.reload()
                                }, 1000)

                          }, function () {
                                vm.$notify({ message: "Could not remove bank account",  type: 'error' });
                          });
                    }
                  }
                });
            }
        }
    }
</script>
<template>
    <span>
        <a @click="open()" class="small button"><i class="el-icon-delete"></i></a>
    </span>
</template>