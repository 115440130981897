<script>
    import moment from 'moment'
    import { Message } from 'element-ui';
    import axios from 'axios';

    export default {
        props: ['errors', 'data', 'url', 'calculator'],
        data() {
            return {
                dialogVisible: false,
                form: {
                    amount: 0,
                    date: null,
                    contribution_src_name: '',
                    contribution_src: 1
                },
                contribution: {
                    amount: 0,
                    contributor: 1,
                    contribution_type: 1
                },
                contributions: []
            }
        },
        computed: {
            'total': function () {
                let total = 0
                for(let i=0; i< this.contributions.length; i++) {
                    total += parseFloat(this.contributions[i].amount)
                }

                return total
            },
            'match': function () {
                return Math.abs(parseFloat(this.form.amount) - this.total) < 5
            }
        },
        methods: {
            save: function(){
                let vm = this;
                let data = this.form
                data.contributions = this.contributions

                axios.post(this.url, data)
                .then(function () {
                    Message.success('Contribution saved successfully')
                    vm.dialogVisible = false
                }, function () {
                    Message.error('Could not submit contribution')
                })

            },
            add: function () {
                this.contributions.push(this.contribution)
                this.contribution = { amount: 0, contributor: 1, contribution_type: 1}

                Message.info('Item added');
            },
            remove: function (item) {
                this.contributions.splice(this.contributions.indexOf(item), 1);
            }
        },
        created: function () {
            this.form.date = moment().format('YYYY-MM-DD')
        }

    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="success button"><i class="el-icon-plus"></i> Invest</button>

        <el-dialog
          title="Invest"
          class="large-dialog"
          :visible.sync="dialogVisible"
        >
          <span>
              <form method="POST" class="form wide cols4" :action="url">
                  <slot name="csrf"></slot>
                  <div class="holder">
                    <label for="amount">Amount | {{ form.amount | currency('') }}</label>
                    <input type="number" step="0.01" name="amount" id="amount" v-model="form.amount" required="true">
                    <form-errors name="amount" :errors="errors"></form-errors>
                  </div>

                  <div class="holder">
                      <label for="date">Date</label>
                      <el-date-picker
                          v-model="form.date"
                          type="date"
                          placeholder="Pick a day"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          required="true"
                        >
                      </el-date-picker>
                      <input name="date" type="hidden" v-model="form.date"/>
                      <form-errors name="date" :errors="errors"></form-errors>
                  </div>

                  <div class="holder">
                      <label for="contribution_src">Source</label>
                      <select name="contribution_src" id="contribution_src" v-model="form.contribution_src">
                          <option value="1" selected>Inflow</option>
                          <option value="2">Transfer In</option>
                      </select>
                  </div>

                  <div class="holder">
                      <label for="contribution_src_name">Transfer Source</label>
                      <input type="text" name="contribution_src_name" id="contribution_src_name" placeholder="Source if transfer" v-model="form.contribution_src_name">
                  </div>

                  <h5 class="px-5">Breakdown</h5>
                  <div class="holder">
                    <label for="contribution_amount">Amount | {{ contribution.amount | currency('') }}</label>
                    <input type="number" step="0.01" name="contribution_amount" id="contribution_amount" v-model="contribution.amount" required="true">
                    <form-errors name="amount" :errors="errors"></form-errors>
                  </div>

                  <div class="holder">
                      <label for="contributor">Contributor</label>
                      <select name="contributor" id="contributor" v-model="contribution.contributor">
                          <option value="1" selected>Employee</option>
                          <option value="2">Employer</option>
                      </select>
                  </div>

                  <div class="holder">
                      <label for="contribution_type">Contribution Type</label>
                      <select name="contribution_type" id="contribution_type" v-model="contribution.contribution_type">
                          <option value="1" selected>Normal</option>
                          <option value="2">Voluntary</option>
                      </select>
                  </div>

                  <div class="holder py-6">
                      <button class="info button" @click.prevent="add"><i class="el-icon-plus"></i> Add</button>
                  </div>

                  <table class="w-full">
                      <thead>
                        <tr><th>Amount</th><th>Contributor</th><th>Contribution Type</th><th>Action</th></tr>
                      </thead>
                      <tbody>
                        <tr v-for="contribution in contributions">
                            <td>{{ contribution.amount | currency('')}}</td>
                            <td>{{ contribution.contributor === 1 ? 'Employee' : 'Employer' }}</td>
                            <td>{{ contribution.contribution_type === 1 ? 'Normal': 'Voluntary'}}</td>
                            <td><button class="info button" @click.prevent="remove(contribution)"><i class="el-icon-minus"></i></button></td>
                        </tr>
                      </tbody>
                  </table>

                  <div class="callout alert w-full" v-if="!match">
                      The amounts do not match. Amount {{ form.amount | currency('')}} Total: {{ total | currency('') }}
                  </div>
                  <div class="w-full">
                      <div class="button-group holder">
                          <button @click.prevent="save" v-if="match" type="submit" class="success button">Save</button>
                          <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                      </div>
                  </div>
              </form>
          </span>
        </el-dialog>
    </div>
</template>