<script>
    export default {
        data(){
            return {
                messages: []
            }
        },
        created: function(){
            let vm = this;
            vm.messages = window.messages;

            for(let i=0; i < vm.messages.length; i++) {
                setTimeout(function () {
                    vm.$notify({
                        message: vm.messages[i].message,
                        type: vm.messages[i].type,
                        duration: 20000 + (i * 200),
                        dangerouslyUseHTMLString: true,
                    });
                }, i * 300);
            }
        }
    }
</script>
<template>
    <span class="hidden">
        <span v-for="message in messages" class="hidden">Message: {{ message.message }} Type: {{message.type}}</span>
    </span>
</template>