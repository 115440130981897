<script>
    export default {
        props: ['errors', 'name']
    }
</script>
<template>
    <span>
        <ul v-if="errors[name]">
            <li v-for="error in errors[name]">{{ error }}</li>
        </ul>
    </span>
</template>
<style scoped>
    li{
        color: orangered;
    }
</style>