<script>
    import axios from 'axios'

    export default {
        props: ['account', 'currencies', 'banks', 'countries', 'client_id', 'approval_id'],
        data(){
            return {
                dialogVisible: false,
                createBankVisible: false,
                createBranchVisible: false,
                account_data: {},
                bank_data: {},
                branch_data: {},
                branches: []
            }
        },
        computed: {
            title: function () {
                return this.account ? 'Edit' : 'Add Bank Account'
            }
        },
        methods: {
            show: function () {
                this.dialogVisible = !this.dialogVisible
            },
            fetch_branches() {
                let vm = this;
                axios.get('/clients/branch/' + this.account_data.bank_id)
                .then(function (response) {
                    vm.branches = response.data
                }, function (response) {
                    vm.$notify({ message: "Could not fetch branches", type: 'error' });
                })
            },
            fetch_banks: function() {
                window.location.reload()
            },
            close_all: function(){
                this.dialogVisible = false;
                this.createBankVisible = false;
                this.createBranchVisible = false;
            },
            save: function () {
                let vm = this;
                axios.post('/clients/bank/account/'+vm.client_id, this.account_data)
                    .then(function (response) {
                        let msg = vm.approval_id ? "Bank account saved for approval" : "Bank account saved successfully";
                        vm.$notify({ message: msg,  type: 'success' });
                        vm.close_all();
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000)
                    }, function () {
                        vm.$notify({ message: "Could not save bank account",  type: 'error' });
                    });


            },
            saveBank:function () {
                let vm = this;

                axios.post('/clients/bank', this.bank_data)
                .then(function (response) {
                    vm.$notify({ message: "Bank & branch saved successfully",  type: 'success' });
                    vm.fetch_banks()
                }, function () {
                    vm.$notify({ message: "Could not save bank", type: 'error' });
                })
            },
            saveBranch: function () {
                let vm = this;
                axios.post('/clients/branch/' + this.account_data.bank_id, this.branch_data)
                    .then(function (response) {
                        vm.branches = response.data;
                        vm.$notify({ message: "Branch saved successfully", type: 'success' });
                        vm.createBranchVisible = false
                    }, function (response) {
                        vm.$notify({ message: "Could not fetch branches", type: 'error' });
                    })
            },
            openBranch: function () {
                if(this.account_data.bank_id){
                    this.createBranchVisible = true
                }
                else {
                    this.$notify({ message: "Please select a bank then add the branch", type: 'error' });
                }
            }
        },
        created: function() {
            if(this.account) {
                this.account_data.bank_id = this.account.bank_id;
                this.fetch_branches();

                this.account_data = {
                    'account_id': this.account.id,
                    'account_name': this.account.name,
                    'account_number': this.account.number,
                    'currency_id': this.account.currency_id,
                    'bank_id': this.account.bank_id,
                    'branch_id': this.account.branch_id
                }
            }
        }
    }
</script>
<template>
    <span>
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
          width="30%"
        >
          <div>
              <form action="" class="form wide cols2">
                  <div class="holder">
                      <label>Bank <span class="text-blue-500"><a  @click="createBankVisible = true"><i class="el-icon-plus"></i> Add bank</a></span></label>

                      <el-select v-model="account_data.bank_id" @change="fetch_branches()" filterable placeholder="Select">
                        <el-option
                          v-for="item in banks"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                  </div>
                  <div class="holder">
                      <label>Currency</label>

                      <el-select v-model="account_data.currency_id">
                          <el-option
                                  v-for="currency in currencies"
                                  :value="currency.id" :label="currency.name" :key="currency.id">
                              {{ currency.name }}
                          </el-option>
                      </el-select>
                  </div>

                  <div class="holder">
                      <label>Branch <span class="text-blue-500"><a  @click="openBranch()"><i class="el-icon-plus"></i> Add branch</a></span></label>

                      <el-select v-model="account_data.branch_id">
                          <el-option
                                  v-for="branch in branches" :value="branch.id" :label="branch.name" :key="branch.id">
                              {{ branch.name }}
                          </el-option>
                      </el-select>
                  </div>

                  <div class="holder">
                      <label>Account Name</label>

                      <input type="text" v-model="account_data.account_name">
                  </div>

                  <div class="holder">
                      <label>Account Number</label>

                      <input type="text" v-model="account_data.account_number">
                  </div>

              </form>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="button" @click="dialogVisible = false">Cancel</button>
            <button class="button" type="primary" @click="save()">Confirm</button>
          </span>

            <el-dialog
                title="Create Bank"
                :visible.sync="createBankVisible"
                append-to-body>
                <div>
                    <form action="" class="form wide cols2">
                        <div class="holder">
                            <label>Bank name</label>

                            <input type="text" v-model="bank_data.name">
                        </div>

                        <div class="holder">
                            <label>Country</label>

                            <el-select v-model="bank_data.country_id" filterable>
                                <el-option v-for="country in countries"
                                           :key="country.id"
                                           :value="country.id"
                                           :label="country.name"
                                >
                                    {{ country.name }}
                                </el-option>
                            </el-select>
                        </div>

                        <div class="holder">
                            <label>Swift code</label>

                            <input type="text" v-model="bank_data.swift_code">
                        </div>

                        <div class="holder">
                            <label>Clearing code</label>

                            <input type="text" v-model="bank_data.clearing_code">
                        </div>

                        <div class="holder">
                            <label>Branch name</label>

                            <input type="text" v-model="bank_data.branch_name">
                        </div>

                        <div class="holder">
                            <label>IBAN</label>

                            <input type="text" v-model="bank_data.iban">
                        </div>

                        <div class="holder">
                            <label>Routing Number</label>

                            <input type="text" v-model="bank_data.routing_number">
                        </div>
                    </form>
                </div>

                <span slot="footer" class="dialog-footer">
                    <button class="button" @click="createBankVisible = false">Cancel</button>
                    <button class="button" type="primary" @click="saveBank()">Confirm</button>
                  </span>
            </el-dialog>

            <el-dialog
                title="Create Branch"
                :visible.sync="createBranchVisible"
                append-to-body>
                <div>
                    <form class="form wide">
                        <input type="text" v-model="branch_data.name" placeholder="Branch Name">
                    </form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <button class="button" @click="createBranchVisible = false">Cancel</button>
                    <button class="button" type="primary" @click="saveBranch()">Confirm</button>
                </span>
            </el-dialog>
        </el-dialog>

        <a class="small button" @click="dialogVisible = true">{{ title }}</a>
    </span>
</template>