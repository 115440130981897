<script>
    import axios from 'axios'
    import moment from 'moment'

    export default {
        props: ['account', 'product', 'url'],
        data: function () {
            return {
                "dialogVisible": false,
                "form": {
                    account_id: null,
                    narration: '',
                    amount: 0,
                    date: null
                },
                "accounts": []
            }
        },
        methods: {
            'search': function (query) {
                let vm = this
                axios.get('/api/accounts/', {params: {'search': query, 'product_id':this.product}})
                .then(function ({data}) {
                    vm.accounts = data.results
                }, function () {

                })
            }
        },
        created(){
            this.form.date = moment().format('YYYY-MM-DD')
        }
    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="button"><i class="el-icon-right"></i> Transfer Cash</button>

        <el-dialog
          title="Transfer Cash"
          :visible.sync="dialogVisible"
        >
          <span>
              <form method="POST" class="form wide cols2" :action="url">
                  <slot name="csrf"></slot>
                  <div class="holder">
                      <label for="client">Receiving Account</label>
                      <el-select required v-model="form.account_id"
                                 placeholder="Please select account" filterable
                                 remote :remote-method="search"
                      >
                        <el-option v-for="account in accounts" :key="account.id"
                          :label="account.client.name + ' - A/C:' + account.number"
                          :value="account.id">
                        </el-option>
                      </el-select>
                      <input name="client" type="hidden" id="client" v-model="form.account_id">
                  </div>
                  <div class="holder">
                      <label for="amount">Amount | {{ form.amount | currency("") }}</label>
                      <input required type="number" step="0.001" min="0" v-model="form.amount" id="amount" name="amount" placeholder="Enter amount">
                  </div>
                  <div class="holder">
                      <label for="date">Date</label>
                      <el-date-picker required
                          v-model="form.date"
                          type="date"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          placeholder="Pick a day">
                        </el-date-picker>
                      <input type="hidden" v-model="form.date" id="date" name="date" >
                  </div>
                  <div class="holder">
                      <label for="narration">Narration</label>
                      <input type="text" v-model="form.narration" id="narration" name="narration" placeholder="Enter narration" required>
                  </div>
                  <div class="w-full">
                      <div class="button-group holder">
                          <button type="submit" class="success button">Save</button>
                          <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                      </div>
                  </div>
              </form>
          </span>
        </el-dialog>
    </div>
</template>