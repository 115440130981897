<script>
    export default {
        props: ['name', 'value'],
        data(){
            return {
                selected: null
            }
        },
        created: function () {
            var first = null;
            first = JSON.parse(this.value)[0];

            if(first) {
                this.selected = first
            }
        }
    }
</script>
<template>
    <span>
        <el-select v-model="selected" filterable :placeholder="'Select '+name">
            <slot></slot>
        </el-select>
        <input type="hidden" :name="name" :value="selected">
    </span>

</template>
<style scoped>

</style>