
<script>
    import axios from "axios";

    export default {
        props: ['url', 'item', 'text', 'title', 'reload', 'btn_class', 'btn_text', 'success_message', 'fail_message'],
        computed: {
            get_title: function () {
                return this.title ? this.title : 'Confirmation'
            },
            should_reload: function () {
                let dont_reload = (this.reload === false) | (this.reload === null);

                return !dont_reload
            },
            get_text: function () {
                return this.text ? this.text : 'Are you sure?'
            },
            get_btn_class: function () {
                return this.btn_class ? this.btn_class : 'small'
            },
            get_btn_text: function () {
                return this.btn_text ? this.btn_text : 'Yes, Proceed'
            }
        },
        methods: {
            open: function() {
                let vm = this;

                this.$alert(vm.get_text, vm.get_title, {
                  confirmButtonText: vm.get_btn_text,
                  callback: action => {
                      if(action === 'confirm') {
                          axios.get(vm.url, {maxRedirects: 0})
                              .then(function (response) {
                                  vm.$notify({
                                      message: vm.success_message ?
                                          vm.success_message : "Completed successfully",
                                      type: 'success'
                                  });

                                  if(vm.should_reload) {
                                      setTimeout(function () {
                                            window.location.reload()
                                      }, 1000)
                                  }
                              }, function () {
                                    vm.$notify({
                                        message: vm.fail_message ? vm.fail_message : "Operation failed!",
                                        type: 'error'
                                    });
                              });
                      }
                  }
                });
            }
        }
    }
</script>
<template>
    <span>
        <a :class="get_btn_class+' button'" @click="open()">
            <slot></slot>
        </a>
    </span>
</template>