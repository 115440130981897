<script>
    export default {
        props: ['approve_url', 'reject_url'],
        data(){
            return {
                'rejectDialogVisible': false,
                'approveDialogVisible': false
            }
        }
    }
</script>
<template>
    <span>
        <a class="button" @click="approveDialogVisible = true">Approve</a>
        <a class="float-right alert button" @click="rejectDialogVisible = true">Reject</a>

        <el-dialog
          title="Approve transaction"
          :visible.sync="approveDialogVisible">
          <div>
              <span>Are you sure?</span>
          </div>
          <span slot="footer" class="dialog-footer">
              <form class="inline" method="POST" :action="approve_url" onsubmit="submit_btn.disabled = true; return true;">
                  <slot name="csrf_token"></slot>
                  <button name="submit_btn" class="button" type="submit">Approve</button>
              </form>
            <button class="secondary button" @click.prevent="approveDialogVisible = false">Cancel</button>
          </span>
        </el-dialog>

        <el-dialog
          title="Reject transaction"
          :visible.sync="rejectDialogVisible">
          <div>
              <span>Please provide a reason</span>

              <form method="POST" :action="reject_url" onsubmit="reject_btn.disabled = true; return true;">
                  <slot name="csrf_token"></slot>
                  <div class="holder">
                      <label>At least 10 characters*</label>
                      <input type="text" name="reason" placeholder="Enter reason">
                  </div>
                  <button class="ml-2 float-right secondary button" @click.prevent="rejectDialogVisible = false">Cancel</button>
                  <button name="reject_btn" class="ml-2 float-right alert button" type="submit">Reject</button>
                  <span class="clearfix"></span>
              </form>
          </div>
        </el-dialog>
    </span>
</template>
