<script>
    export default {
        props: ['title', 'value', 'bg', 'target', 'icon'],
    }
</script>
<template>
    <div class="w-full md:w-1/2 xl:w-1/3 p-3 mb-4">
        <div class="border-b-4 rounded-sm shadow-lg p-5" :class="'bg-'+bg+'-100 border-'+bg+'-500'">
            <a :href="target">
            <div class="flex flex-row items-center">
                <div class="flex-shrink pr-4">
                    <div class="rounded-full p-5" :class="'bg-'+bg+'-600'">
                        <i class="fa fa-2x fa-inverse" :class="icon"></i>
                    </div>
                </div>
                <div class="flex-1 text-right md:text-center">
                    <h5 class="font-bold uppercase text-gray-600">{{ title }}</h5>
                    <h3 class="font-bold text-3xl">{{ value }}</h3>
                </div>
            </div>
            </a>
        </div>

        <!-- styles for PurgeCSS -->
        <span style="display: none" class="bg-indigo-100 bg-indigo-600 border-indigo-500"></span>
        <span style="display: none" class="bg-green-100 bg-green-600 border-green-500"></span>
        <span style="display: none" class="bg-orange-100 bg-orange-600 border-orange-500"></span>
    </div>
</template>