<script>
    import axios from 'axios'

    export default {
        props: ['security_id', 'holding_id'],
        data(){
            return {
                'date': null,
                'value_as_at': null
            }
        },
        watch: {
            'date': function () {
                let vm = this;

                const url = '/api/portfolio/securities/'+this.security_id+'/fixed_income/holdings/'+this.holding_id+'?date='+this.date;

                axios.get(url)
                    .then(function ({data}) {
                        vm.value_as_at = data.market_value
                    }, function () {

                    });
            }
        },
        computed: {
            'value_at_date': function () {
                return this.value_as_at
            }
        },
        methods: {
            'attach': function () {
                let vm = this;
                let date_input = document.querySelector('#id_date');

                date_input.addEventListener("blur",function (event) {
                    setTimeout(function () {
                        vm.date = date_input.value;
                    }, 300)
                });
            }
        },
        mounted() {
            this.attach();
        }
    }
</script>
<template>
    <div class="page-content">
        <label>Value as at {{ date }}</label> {{ value_as_at | currency('') }}
    </div>
</template>