<script>
    import axios from 'axios'

    export default {
        props: ['products', 'client', 'approved'],
        data(){
            return {
                dialogVisible: false,
                account: {}
            }
        },
        computed: {
            url: function () {
                return this.approved ? '/clients/'+this.client+'/accounts/additional/'
                    : '/clients/'+this.client+'/accounts/create/'
            },
            title: function () {
                return this.approved ? 'Add Additional Account' :'Create Account & Request Approval'
            }
        },
        methods: {
            open: function () {
                this.dialogVisible = true
            },
            submit: function () {
                let vm = this;

                axios.post(this.url, this.account)
                .then(function () {
                    vm.dialogVisible = false;
                    vm.$notify({ message: "Account is now awaiting approval",  type: 'success' });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000)
                }, function () {
                    vm.$notify({ message: "Could not request approval",  type: 'error' });
                })
            }
        }
    }
</script>
<template>
    <span>
        <el-dialog
          :title="title"
          :visible.sync="dialogVisible"
        >
          <div>
              <form class="wide">
                  <div class="holder">
                      <label>Select Product</label>
                      <el-select name="product_id" v-model="account.product_id">
                        <el-option v-for="product in products" :key="product.id" :label="product.name" :value="product.id"></el-option>
                      </el-select>
                  </div>
              </form>
          </div>
          <span slot="footer" class="dialog-footer">
            <button class="secondary button" @click="dialogVisible = false">Cancel</button>
            <button class="button" type="primary" @click="submit()">Request Approval</button>
          </span>
        </el-dialog>

        <a @click="open()" class="button"><i class="el-icon-check"></i> {{ title }}</a>
    </span>
</template>
<style>
    el-select, .el-select {
        width: 100%;
    }
</style>