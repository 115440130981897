<script>
    export default {
        props: ['client_url'],
        data() {
          return {
            dialogVisible: false
          };
        },
    }
</script>
<template>
    <div>
        <span>
            <a class="button" @click="dialogVisible = true">View client (in page)</a>
            <a class="button" target="_blank" :href="client_url">View client in new tab</a>
        </span>
        <el-dialog
          title="Client details"
          :visible.sync="dialogVisible"
          width="100%"
          class="fullscreen"
        >
            <slot></slot>
            <span slot="footer" class="dialog-footer">
                <button class="secondary button" @click="dialogVisible = false">Close</button>
            </span>
        </el-dialog>
    </div>
</template>