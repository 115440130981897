<script>
    import {Chart} from 'highcharts-vue'
    import axios from 'axios'

    export default {
        data(){
            return {
                assets: [],
                liabilities: [],
            }
        },
        computed: {
            'chartOptions': function () {
                return {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: 'Assets Under Management'
                    },
                    xAxis: {
                        type: 'category',
                        title: {
                            text: null
                        },
                        scrollbar: {
                            enabled: true
                        },
                        tickLength: 0
                    },
                    yAxis: {
                        title: {
                            text: 'Amount',
                            align: 'high'
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    legend: {
                        enabled: true
                    },
                    credits: {
                        enabled: false
                    },
                    series: [
                        { name: 'Assets', data: this.assets },
                        { name: 'Liabilities', data: this.liabilities }
                    ]
                }
            },
        },
        components: {
            chart: Chart
        },
        mounted() {
            let vm = this

            axios.get('/dashboard/aum-data/')
                .then(function (response) {
                    vm.assets = []
                    vm.liabilities = []

                    for(let i=0; i< response.data.length; i++) {
                        let el = response.data[i]
                        vm.assets.push([el.name, el.asset_aum])
                        vm.liabilities.push([el.name, el.liabilities_aum])
                    }
                }, function () {

                })
        }
    }
</script>
<template>
    <div>
        <chart :options="chartOptions"></chart>
    </div>
</template>