import Vue from 'vue'

Vue.component('top-bar', require('./ui/components/topbar').default);
Vue.component('side-bar', require('./ui/components/sidebar').default);
Vue.component('login-form', require('./ui/components/auth/login').default);
Vue.component('date-picker', require('./ui/components/forms/widgets/datepicker').default);
Vue.component('searchable-select', require('./ui/components/forms/widgets/searchableselect').default);
Vue.component('remote-table', require('./ui/components/table').default);
Vue.component('notification', require('./ui/components/notification').default);
Vue.component('add-bank-account', require('./ui/components/clients/add-bank-account').default);
Vue.component('delete-bank-account', require('./ui/components/clients/delete-bank-account').default);
Vue.component('confirmation', require('./ui/components/confirmation').default);
Vue.component('request-client-approval', require('./ui/components/clients/request-approval').default);
Vue.component('add-fa', require('./ui/components/clients/add-fa').default);
Vue.component('client-details-modal', require('./ui/components/clients/client-details-modal').default);
Vue.component('approve-reject-transaction', require('./ui/components/approvals/approve-reject-transaction').default);
Vue.component('approval-comments', require('./ui/components/approvals/comments').default);
Vue.component('add-inflow', require('./ui/components/clients/wallets/add-inflow').default);
Vue.component('transfer-cash', require('./ui/components/clients/wallets/transfer').default);
Vue.component('form-errors', require('./ui/components/forms/errors').default);
Vue.component('deactivate-client', require('./ui/components/clients/deactivate-client').default);

//Dashboard
Vue.component('dashboard-card', require('./ui/components/dashboard/card').default)
Vue.component('dashboard-aum-chart', require('./ui/components/dashboard/aum-chart').default)

// Investments
Vue.component('invest', require('./ui/components/investments/accounts/invest').default);
Vue.component('pension-invest', require('./ui/components/investments/accounts/pension_invest').default);
Vue.component('withdraw', require('./ui/components/investments/accounts/withdraw').default);
Vue.component('rollover', require('./ui/components/investments/accounts/rollover').default);
Vue.component('statement', require('./ui/components/investments/accounts/statement').default);
Vue.component('transfer-investment', require('./ui/components/investments/accounts/transfer').default);
Vue.component('edit-investment', require('./ui/components/investments/accounts/edit_investment').default);
Vue.component('edit-investment-commission', require('./ui/components/investments/accounts/edit_investment_commission').default)
Vue.component('transfer-units', require('./ui/components/investments/transactions/units-transfer').default);

//Portfolio
Vue.component('portfolio-withdraw-confirm', require('./ui/components/portfolio/withdraw-confirm').default);
Vue.component('portfolio-transfer', require('./ui/components/portfolio/transfer').default);

//Unit Trust
Vue.component('create-rate', require('./ui/components/unit_trust/create_rate').default);
Vue.component('asset-allocation', require('./ui/components/unit_trust/asset_allocation_status').default);

Vue.component('year', {data: () => { return {year: (new Date()).getFullYear()}}, template: '<span>{{ year }}</span>'});

export default {

}
