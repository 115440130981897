<template>
        <el-menu
          class="el-menu-vertical-demo shadow-xs pt-6"
          @open="handleOpen"
          @close="handleClose"
        >

          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span>Clients</span>
            </template>
            <el-menu-item index="1-1"><a href="/clients/applications">On-boarding</a></el-menu-item>
            <el-menu-item index="1-2"><a href="/clients">Client Details</a></el-menu-item>
            <el-menu-item index="1-3"><a href="/clients/users">Users</a></el-menu-item>
            <el-menu-item index="1-4"><a href="/clients/financial_advisers">Financial Advisors</a></el-menu-item>
            <el-menu-item index="1-5"><a href="/clients/blacklists">Blacklists</a></el-menu-item>
            <el-menu-item index="1-6"><a href="/clients/banks">Banks</a></el-menu-item>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-wallet"></i>
              <span>Investments</span>
            </template>
            <el-menu-item index="2-1"><a href="/investments">Accounts</a></el-menu-item>
            <el-menu-item index="2-2"><a href="/investments/instructions">Orders</a></el-menu-item>
            <el-menu-item index="2-3"><a href="/investments/batches">Batches</a></el-menu-item>
          </el-submenu>

          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-office-building"></i>
              <span>Portfolio</span>
            </template>
            <el-menu-item index="3-1"><a href="/portfolio">Securities</a></el-menu-item>
            <el-menu-item index="3-2"><a href="/portfolio/accounts">Bank Accounts</a></el-menu-item>
          </el-submenu>

          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-check"></i>
              <span>Approvals</span>
            </template>
            <el-menu-item index="4-1"><a href="/approvals">Pending</a></el-menu-item>
            <el-menu-item index="4-2"><a href="/approvals/rejected">Rejected</a></el-menu-item>
            <el-menu-item index="4-3"><a href="/approvals/complete">Completed</a></el-menu-item>
          </el-submenu>

          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>Tools</span>
            </template>
            <el-menu-item index="5-1"><a href="/reporting">Reports</a></el-menu-item>
            <el-menu-item index="5-2"><a href="/documents">Documents</a></el-menu-item>
          </el-submenu>

          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>Settings</span>
            </template>
            <el-menu-item index="6-1"><a href="/funds">Funds</a></el-menu-item>
            <el-menu-item index="6-2"><a href="/users">Users</a></el-menu-item>
            <el-menu-item index="6-3"><a href="/admin-nkg">Admin</a></el-menu-item>
          </el-submenu>
        </el-menu>
</template>

<script>
  export default {
    methods: {
      handleOpen(key, keyPath) {
        // console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        // console.log(key, keyPath);
      }
    }
  }
</script>
<style scoped>
  a, .block{
    display: block;
  }
  a.inline {
    display: inline;
  }
</style>