module.exports = function debounce (fn, delay) {
  let timeoutID = null;

  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
};