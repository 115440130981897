<script>
    import moment from 'moment'
    export default {
        props: ['errors', 'data', 'url', 'calculator', 'investment'],
        data() {
            return {
                dialogVisible: false,
                form: {
                    'amount': 0,
                    'date': null,
                    'maturity_date': null,
                    'rate': null
                }
            }
        },
        created: function () {
            this.form.date = moment(this.investment.maturity_date).add(1, 'days').format('YYYY-MM-DD');
        }
    }
</script>
<template>
    <div>
        <button @click="dialogVisible = true" class="success button"><i class="el-icon-refresh-right"></i> Rollover</button>

        <el-dialog
          title="Rollover"
          :visible.sync="dialogVisible"
        >
            <span>
                <div class="holder pl-4 pb-4">
                    <span>Amount Available: {{ investment.total_maturity | currency('') }}</span><br>
                    <span>Maturity Date: {{ investment.maturity_date | date }}</span>
                </div>
                <form method="POST" class="form wide cols2" :action="url">
                    <slot name="csrf"></slot>
                    <div class="holder">
                        <label for="amount">Amount To Withdraw | {{ form.amount | currency('') }}</label>
                        <input type="number" step="0.01" name="amount" id="amount" v-model="form.amount" required="true">
                        <form-errors name="amount" :errors="errors"></form-errors>
                    </div>
                    <div class="holder">
                        <label for="date">New Investment Value Date</label>
                        <el-date-picker
                                v-model="form.date"
                                type="date"
                                placeholder="Pick a day"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                required="true"
                        >
                        </el-date-picker>
                        <input name="date" type="hidden" v-model="form.date"/>
                        <form-errors name="date" :errors="errors"></form-errors>
                    </div>

                  <template v-if="calculator === 'fixed_interest' ">
                      <div class="holder">
                          <label for="maturity_date">Maturity Date</label>
                          <el-date-picker
                              v-model="form.maturity_date"
                              type="date"
                              placeholder="Pick a day"
                              format="yyyy-MM-dd"
                              value-format="yyyy-MM-dd"
                              required="true"
                              id="maturity_date"
                            >
                            </el-date-picker>
                            <input name="maturity_date" type="hidden" v-model="form.maturity_date"/>
                            <form-errors name="maturity_date" :errors="errors"></form-errors>
                      </div>

                      <div class="holder">
                          <label for="rate">Interest Rate (%)</label>
                          <input name="rate" type="number" id="rate" v-model="form.rate" step="0.001" required="required">
                          <form-errors name="rate" :errors="errors"></form-errors>
                      </div>

                       <div class="holder">
                        <label for="award_commission">Award Commission</label>
                        <select name="award_commission" id="award_commission">
                              <option selected value="1">Full</option>
                              <option value="0.5">Half</option>
                              <option value="0">None</option>
                        </select>
                        <form-errors name="award_commission" :errors="errors"></form-errors>
                      </div>
                  </template>
                  <input type="hidden" name="investment_id" v-model="investment.id"/>
                  <div class="w-full">
                      <div class="button-group holder">
                      <button type="submit" class="success button">Save</button>
                      <a @click.prevent="dialogVisible = false" class="secondary button">Cancel</a>
                  </div>
                  </div>
              </form>
          </span>
        </el-dialog>
    </div>
</template>