<script>
    export default {
        props: ['from_account'],
        data(){
            return {
                'to_account': null,
                'exchange': null,
                'amount': null,
                'reversed': false
            }
        },
        computed: {
            'exchange_amount': function () {
                return this.effective_exchange * this.amount
            },
            'effective_exchange': function () {
                return this.reversed ? 1/this.exchange : this.exchange;
            }
        },
        methods: {
            'reverse': function () {
                this.exchange = 1/this.exchange
            }
        },
        mounted() {
            let vm = this;
            let to_acc_input = document.querySelector('#id_account');
            let amount_input = document.querySelector('#id_amount');
            let exchange_input = document.querySelector('#id_exchange_rate');
            vm.to_account = to_acc_input.options[to_acc_input.selectedIndex].text;
            vm.exchange = exchange_input.value;
            vm.amount = amount_input.value;

            to_acc_input.addEventListener('input', function (evt) {
                vm.to_account = to_acc_input.options[to_acc_input.selectedIndex].text;
            });

            amount_input.addEventListener('input', function () {
                setTimeout(function () {
                    vm.amount = amount_input.value;
                }, 300)
            });

            exchange_input.addEventListener('input', function () {
                setTimeout(function () {
                    vm.exchange = exchange_input.value;
                },300)
            });
        }
    }
</script>
<template>
    <div class="flex flex-wrap">
        <div class="holder px-0">
            <input type="checkbox" v-model="reversed" name="reversed" id="reversed">
            <label for="reversed">Exchange reversed</label>
        </div>
        <div class="holder"></div>
        <div class="page-content md:w-1/2 px-2">
            <label>Amount Out: {{ from_account }}</label> {{ amount | currency('') }}
        </div>
        <div class="page-content md:w-1/2 px-2">
            <label>Amount In: {{ to_account }}</label> {{ exchange_amount | currency('') }}
        </div>
    </div>
</template>
